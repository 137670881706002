export default defineNuxtRouteMiddleware(async (to, from) => {
    const authStore = useAuthStore();
    const { userStateReady, isAuthenticated, sbUser, hasAccountAccess } =
        storeToRefs(authStore);

    const onboardStore = useOnboardStore();
    const { onboardingProgress } = storeToRefs(onboardStore);

    console.log("onboarding middleware to.path: ", to.path);

    if (to.path === "/onboard") {
        return;
    }

    if (userStateReady.value && !isAuthenticated.value) {
        console.log("User is ready but not authenticated.");

        let pathRequiresAuth = [
            "/onboard/card-upload",
            "/onboard/insurance",
            "/onboard/clients",
            "/onboard/eligibility",
            "/onboard/billing",
        ];
        if (pathRequiresAuth.includes(to.path)) {
            return navigateTo("/onboard");
        }
    } else if (isAuthenticated.value && hasAccountAccess.value) {
        console.log("User is authenticated and has account access.");
        // onboardingProgress.filter((step) => step.path === to.path);
    }
});
